import React from 'react';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import { media } from '@glitz/core';
import WebinarItemsList from '../Shared/WebinarItemsList/WebinarItemsList';

import WebinarsListPageModel from './Models/WebinarsListPageModel.interface';
import Grid from '../Shared/Grid/Grid';
import ContentArea from '../Shared/ContentArea/ContentArea';

function WebinarDetailPage() {
  const {
    header,
    subHeader,
    introText,
    webinarItems,
    contentArea,
    inEditMode,
  } = useCurrentPage<WebinarsListPageModel>();

  return (
    <>
      <Container>
        <Content>
          {header && (
            <Title {...applyEditModeAttr(inEditMode && 'Header')}>
              {header}
            </Title>
          )}
          {subHeader && (
            <SubHeader {...applyEditModeAttr(inEditMode && 'SubHeader')}>
              {subHeader}
            </SubHeader>
          )}
          {introText && (
            <IntroText {...applyEditModeAttr(inEditMode && 'IntroText')}>
              {introText}
            </IntroText>
          )}
        </Content>
        <Grid
          type={12}
          data-content-area
          {...applyEditModeAttr(inEditMode && 'MainContent')}
        >
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </Grid>
        <WebinarItemsList items={webinarItems} />
      </Container>
    </>
  );
}

const Container = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto', bottom: theme.spacing(16) },
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.none,
    },
  }),
});

const Content = styled.div({
  marginBottom: theme.spacing(8),
  ...media(theme.mediaQuery.mediaMinMedium, {
    marginBottom: theme.spacing(16),
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.spacing(2),
    },
  }),
});

const Title = styled.h1({
  font: {
    family: theme.fontFamily.heading,
    size: theme.iota,
  },
  color: theme.black,
  marginBottom: theme.spacing(4),
});

const SubHeader = styled.p({
  font: {
    family: theme.fontFamily.heading,
    size: theme.delta,
  },
  color: theme.black,
  marginBottom: theme.spacing(4),
});

const IntroText = styled.p({
  color: theme.black,
  lineHeight: theme.lineHeight.normal,
});

export default WebinarDetailPage;
