import React from 'react';
import KexLink from '../../Shared/KexLink/KexLink';
import { styled, theme } from '../../Theme';
import ImageRatio from '../../Shared/Image/ImageRatio';
import { useAppSettingsData } from '../../Shared/AppSettingsProvider/AppSettingsProvider';
import EventBlockModelItem from './Models/EventBlockItem.interface';

type PropTypes = {
  item: EventBlockModelItem;
};

function Webinar({
  item: { headerImage, header, date, location, duration, url },
}: PropTypes) {
  const {
    translations: {
      'webinarDetailPage/date': dateLabel,
      'webinarDetailPage/location': locationLabel,
      'webinarDetailPage/duration': durationLabel,
    },
  } = useAppSettingsData();

  return (
    <styled.Div>
      {headerImage && (
        <KexLink href={url}>
          <ImageRatio src={headerImage.src || ''} alt={headerImage.alt || ''} />
        </KexLink>
      )}
      {header && (
        <Header>
          <KexLink href={url}>{header}</KexLink>
        </Header>
      )}
      {date && (
        <Attribute>
          {dateLabel}
          <Value>{date}</Value>
        </Attribute>
      )}
      {location && (
        <Attribute>
          {locationLabel} <Value>{location}</Value>
        </Attribute>
      )}
      {duration && (
        <Attribute>
          {durationLabel} <Value>{duration}</Value>
        </Attribute>
      )}
    </styled.Div>
  );
}

const Header = styled.h3({
  font: {
    family: theme.fontFamily.heading,
    size: theme.epsilon,
  },
  color: theme.blueDark,
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
});

const Attribute = styled.p({
  font: { size: theme.beta, weight: theme.fontWeight.bold },
  color: theme.black,
});

const Value = styled.span({
  fontWeight: theme.fontWeight.normal,
});

export default Webinar;
