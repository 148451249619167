import React from 'react';
import { styled, theme } from '../../Theme';
import { media } from '@glitz/core';
import Webinar from './../../Blocks/EventBlock/Webinar';
import WebinarsDetailPageModel from './../../WebinarsDetailPage/Models/WebinarsDetailPageModel.interface';

type PropTypes = {
  items: WebinarsDetailPageModel[];
};

function WebinarItemsList({ items }: PropTypes) {
  return (
    <ResultWrapper>
      {items &&
        !!items.length &&
        items.map((item, i) => (
          <Webinar
            item={{
              date: item.date,
              duration: item.duration,
              header: item.header,
              headerImage: item.headerImage,
              location: item.location,
              url: item.pageUrl,
            }}
            key={i + item.pageUrl}
          />
        ))}
    </ResultWrapper>
  );
}

const ResultWrapper = styled.div({
  display: 'grid',
  gridGap: theme.spacing(8),
  gridTemplateColumns: '1fr',
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridTemplateColumns: '1fr 1fr',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '1fr 1fr 1fr',
  }),
  margin: { y: theme.spacing(8) },
});

export default WebinarItemsList;
